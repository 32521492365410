import React, { useEffect, useState } from "react";
import "./css/Blog.css";
import AuthorImg from "../assets/logo192.png";
import { Link } from "react-router-dom";
import {
  server_post_data,
  get_blog_details_url,
} from "../ServiceConnection/serviceconnection.js";
import { useLocation } from "react-router-dom";
import DOMPurify from "dompurify";
import Header from "./Header.js";
import { ShoppingBag } from "lucide-react";
import { X } from "lucide-react";

function Blog() {
  const [showLoader, setShowLoader] = useState(false);
  const [VacancytData, setVacancytData] = useState([]);
  const [data_blog_extra, setdata_blog_extra] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);

  const handleLinkClick = (link, blank = "") => {
    // Reload the page when the link is clicked
    if (blank === "") {
      window.location.href = link;
    } else {
      window.open(link, blank);
    }
  };

  const DateormateBlogChange = (originalDateString) => {
    const dateObject = new Date(originalDateString);

    const formattedDateString = dateObject.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    return formattedDateString;
  };

  const handleFetchData = async () => {
    setShowLoader(true);

    var form_data = new FormData();
    console.log(currentUrl);
    form_data.append("current_url", "/" + currentUrl);
    await server_post_data(get_blog_details_url, form_data)
      .then((Response) => {
        console.log(Response.data.message);
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          if (Response.data.message.data_blog.length > 0) {
            setVacancytData(Response.data.message.data_blog[0]);
            setdata_blog_extra(Response.data.message.data_blog);
            setSEOloop(Response.data.message.data_seo);
          }
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/blog/blog_detail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });
      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }

    return data_seo_link_final;
  };
  const [isTextVisible, setIsTextVisible] = useState(true);

  const handleToggleVisibility = () => {
    setIsTextVisible(!isTextVisible);
  };
  return (
    <div className="readblog">
      <Header />
      <div className="iconflotSubcont">
        <div
          className="icon-container"
          onClick={() => (window.location.href = "/SaleProduct")}
        >
          <ShoppingBag size={24} className="shopping-bag-icon" />
        </div>

        {isTextVisible && (
          <div className="hover-text-container position-relative">
            <div className="hover-text ">
              <p className="m-0" onClick={() => (window.location.href = "/SaleProduct")}>
                Own This Product Today!
              </p>
              <p className="m-0" onClick={() => (window.location.href = "/SaleProduct")}>
                {" "}
                Click to learn more!
              </p>

              <button onClick={handleToggleVisibility} className="close-button">
                <X className="close-icon" />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className={showLoader ? "loading" : ""}></div>
      <div className="readblogContaienr">
        <div className="container-lg">
          <div className="bloghead">
            <h2>{VacancytData.title_name}</h2>
          </div>
          <div className="blogImgcontianer">
            <div className="blogbydetails">
              <img
                fetchPriority="high"
                loading="lazy"
                src={AuthorImg}
                alt="Chat GPT notepad"
                title="Notepad GPT"
              />
              <div className="authorDetains">
                <p>
                  By {VacancytData.author} | Published on{" "}
                  {DateormateBlogChange(VacancytData.entry_date)} | 4 min read
                </p>
              </div>
            </div>
            <div className="blogMAinImg">
              <img
                src={VacancytData.image_url}
                alt="Chat GPT notepad"
                title="Notepad GPT"
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-11 m-auto">
            <div className="blogTextContainer">
              <div className="row m-0">
                <div className="col-lg-8 col-md-7">
                  <div className="readblogtext">{VacancytData.tag_line}</div>
                  <div className="readblogtext">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(VacancytData.description),
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-5 padright">
                  <div className="blogListContaienr">
                    <h5 style={{ color: "#42526E" }}>Popular Blogs</h5>
                    <div className="blogList">
                      <ul>
                        {data_blog_extra.map((landmar, index) => (
                          <Link
                            onClick={() =>
                              handleLinkClick(
                                match_and_return_seo_link(landmar.primary_id)
                              )
                            }
                          >
                            <li>
                              <div className="blogListItem">
                                <img
                                  src={landmar.image_url}
                                  alt="Chat GPT notepad"
                                  title="Notepad GPT"
                                />
                                <div className="blogListItemHead">
                                  <h6>
                                    {DateormateBlogChange(landmar.entry_date)} |{" "}
                                    {landmar.author}
                                  </h6>
                                  <p>{landmar.title_name}</p>
                                </div>
                              </div>
                            </li>
                          </Link>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
