import { useState } from "react";
import "./css/ContactUs.css";

import HeaderNew from "./HeaderNew";
import LocationI from "../assets/location.png";
import Email from "../assets/chatSupport.png";
import phonee from "../assets/phoneeee.png";
import AdComponent from "./AdComponent";
import { ShoppingBag } from "lucide-react";
import { X } from "lucide-react";
function ContactUs() {
  const [isTextVisible, setIsTextVisible] = useState(true);

  const handleToggleVisibility = () => {
    setIsTextVisible(!isTextVisible);
  };
  return (
    <>
      {" "}
      <div className="headerBttmMargin">
        {" "}
        <HeaderNew />
      </div>
      <div className="iconflotSubcont">
        <div
          className="icon-container"
          onClick={() => (window.location.href = "/SaleProduct")}
        >
          <ShoppingBag size={24} className="shopping-bag-icon" />
        </div>

        {isTextVisible && (
          <div className="hover-text-container position-relative">
            <div className="hover-text ">
              <p className="m-0" onClick={() => (window.location.href = "/SaleProduct")}>
                Own This Product Today!
              </p>
              <p className="m-0" onClick={() => (window.location.href = "/SaleProduct")}>
                {" "}
                Click to learn more!
              </p>

              <button onClick={handleToggleVisibility} className="close-button">
                <X className="close-icon" />
              </button>
            </div>
          </div>
        )}
      </div>
      <div id="body">
        <div className="App">
          <header id="navbar"></header>
          <section id="about">
            <h2>Contact Us</h2>
          </section>
          <AdComponent
            slot="4982546863"
            format="auto"
            fullWidthResponsive="true"
          />
          <div className="container">
            <section className="imgTextSection">
              <div className="row m-0">
                <div className="col-md-6">
                  <div className="headImg">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14665.173399049312!2d77.4359186!3d23.2324099!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c42debb09fefb%3A0xfdf0228c14c3fe53!2sTriosoft%20Technologies%20Pvt.%20Ltd.%20l%20Software%20Company!5e0!3m2!1sen!2sin!4v1713359377086!5m2!1sen!2sin"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="headText">
                    <div className="email-info-container">
                      <h5>Contact Information</h5>
                      <div
                        className="reservDetails"
                        style={{ marginBottom: "1.5rem" }}
                      >
                        <div className="reservDetailsContainer">
                          <ul
                            style={{
                              listStyle: "none",
                              padding: 0,
                              margin: "1.5rem 0",
                            }}
                          >
                            <li>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "0.5rem",
                                  columnGap: "0.5rem",
                                }}
                              >
                                <img
                                  style={{ width: "2.2rem", height: "2.2rem" }}
                                  src={phonee}
                                  alt="Chat GPT notepad"
                                  title="Notepad GPT"
                                />
                                <p style={{ margin: 0, fontSize: "15px" }}>
                                  +917880016644
                                </p>
                              </div>
                            </li>
                            <li>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "0.5rem",
                                  columnGap: "0.5rem",
                                }}
                              >
                                <img
                                  style={{ width: "2.2rem", height: "2.2rem" }}
                                  src={Email}
                                  alt="Chat GPT notepad"
                                  title="Notepad GPT"
                                />
                                <p style={{ margin: 0, fontSize: "15px" }}>
                                  support@triosoft.ai
                                </p>
                              </div>
                            </li>
                            <li>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  marginBottom: "0.5rem",
                                  columnGap: "0.5rem",
                                }}
                              >
                                <img
                                  style={{ width: "2.2rem", height: "2.2rem" }}
                                  src={LocationI}
                                  alt="Chat GPT notepad"
                                  title="Notepad GPT"
                                />
                                <p style={{ margin: 0, fontSize: "15px" }}>
                                  Plot No. 96, 2nd Floor, Gargi Rani Complex,
                                  Opposite Indian Overseas Bank, M.P. Nagar,
                                  Zone-II, Bhopal, Madhya Pradesh India- 462011
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="legalInfo">
                        <h5 className="mb-3">Legal Information</h5>
                        <p className="mb-2">
                          All content provided by ChatGPT Notepad, including but
                          not limited to text, graphics, logos, and software, is
                          the property of ChatGPT Notepad or its licensors and
                          is protected by intellectual property laws. Users may
                          not reproduce, distribute, or create derivative works
                          from the content provided by the service without prior
                          written consent
                        </p>
                        <p className="mb-2">
                          We strive to respond to all inquiries within 24-48
                          hours.
                        </p>
                        <p>Thank you for using Online ChatGPT Notepad!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <AdComponent
              slot="6948017615"
              format="autorelaxed"
              fullWidthResponsive="false"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
