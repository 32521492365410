import { useState } from "react";
import HeaderNew from "./HeaderNew";
import "./css/AboutUs.css";
import NotePade from "../assets/noterpade.png";
import AdComponent from "./AdComponent";
import { ShoppingBag } from "lucide-react";
import { X } from "lucide-react";
const AboutUs = () => {
  const [isTextVisible, setIsTextVisible] = useState(true);

  const handleToggleVisibility = () => {
    setIsTextVisible(!isTextVisible);
  };
  return (
    <>
      {" "}
      <div className="headerBttmMargin">
        {" "}
        <HeaderNew />
      </div>
      <div className="iconflotSubcont">
        <div
          className="icon-container"
          onClick={() => (window.location.href = "/SaleProduct")}
        >
          <ShoppingBag size={24} className="shopping-bag-icon" />
        </div>

        {isTextVisible && (
          <div className="hover-text-container position-relative">
            <div className="hover-text ">
              <p className="m-0" onClick={() => (window.location.href = "/SaleProduct")}>
                Own This Product Today!
              </p>
              <p className="m-0" onClick={() => (window.location.href = "/SaleProduct")}>
                {" "}
                Click to learn more!
              </p>

              <button onClick={handleToggleVisibility} className="close-button">
                <X className="close-icon" />
              </button>
            </div>
          </div>
        )}
      </div>
      <div id="body">
        <div className="App">
          <header id="navbar"></header>
          <section id="about" style={{ paddingTop: "1rem" }}>
            <h1 hidden>About Us</h1>
            <h2>About Us</h2>
          </section>

          <div className="container">
            <AdComponent
              slot="4982546863"
              format="auto"
              fullWidthResponsive="true"
            />
            <section className="imgTextSection">
              <div className="row m-0 colRevrs">
                <div className="col-md-6">
                  <div className="headImg">
                    <img
                      src={NotePade}
                      alt="Chat GPT notepad"
                      title="Notepad GPT"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="headText">
                    <div style={{ height: "auto" }}>
                      <h3>A Free Browser-Based Notetaking Tool!</h3>
                      <p>
                        A Note-Taking Tool That Removes Asterisks When Copying
                        From ChatGPT! Can be used well for formatting the
                        ChatGPT content.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <AdComponent
              slot="6948017615"
              format="autorelaxed"
              fullWidthResponsive="false"
            />
            <section id="features">
              <h3>Features</h3>
              <ul>
                <li>
                  <strong>Friendly Interface:</strong> Enjoy a simple, clean
                  design that helps you stay focused on your writing without any
                  distractions.
                </li>
                <li>
                  <strong>Easy Font Customization:</strong> Effortlessly change
                  the look of your notes with three different fonts and styles.
                </li>
                <li>
                  <strong>Real-Time Word Count:</strong> Keep track of your word
                  count as you type, making it easy to meet your writing goals.
                </li>
                <li>
                  <strong>Seamless Text Formatting:</strong> Copy text from
                  ChatGPT without any unwanted asterisks, ensuring your notes
                  look exactly the way you want them to
                </li>
              </ul>
            </section>
            <section id="what-is">
              <div className="what-is-chatgpt">
                <h3>What exactly is ChatGPT Notepad?</h3>
                <p>
                  The ChatGPT Notepad is your go-to online text editor, perfect
                  for creating, saving, and editing notes or documents right in
                  your web browser. Whether you're jotting down ideas, taking
                  detailed notes, or collaborating with others, this digital
                  tool makes it easy and convenient.
                </p>

                <p style={{ fontWeight: "500" }}>
                  Here’s why you’ll love using ChatGPT Notepad:
                </p>

                <ul className="whyLi">
                  <li>
                    <strong>Accessibility:</strong> Access your notes from any
                    device with an internet connection, making it ideal for
                    users on the go.
                  </li>
                  <li>
                    <strong>User-Friendly Features:</strong> Enjoy basic text
                    editing tools like formatting options, numbering, and bullet
                    points, along with advanced features like word count and
                    three unique text styles.
                  </li>
                  <li>
                    <strong>Collaboration Made Easy:</strong> Work together with
                    others in real-time, allowing multiple users to edit the
                    same document simultaneously.
                  </li>
                  <li>
                    <strong>Seamless Integration:</strong> Experience smooth
                    integration with ChatGPT, with one standout feature being
                    the ability to copy text without unwanted asterisks.
                  </li>
                </ul>

                <p>
                  With ChatGPT Notepad, managing your notes and collaborating
                  with others has never been simpler or more efficient.
                </p>

                <p>
                  It can be used for various purposes, such as taking notes,
                  drafting documents, brainstorming ideas, creating to-do lists,
                  and collaborating on projects. ChatGPT notepad can be
                  particularly helpful to students, writers, professionals, and
                  everyone who needs a simple yet efficient way to manage and
                  organize their information and thoughts.
                </p>
              </div>
            </section>

            <section id="what-is">
              <div className="what-is-chatgpt">
                <h3>Browser Compatibility</h3>
                <p>
                  ChatGPT Notepad supports all modern web browsers, including
                  Mozilla, Safari, Opera, Edge, Chrome, and Steam. To use the
                  application, you need only enable JavaScript.
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
