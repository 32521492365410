import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import "./css/headerNew.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./css/Notepad.css";
import "typeface-roboto";
import Logo from "../assets/logo192.png";
import FeedBackModal from "./FeedBackModal";

const HeaderNew = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [bgColor, setBgColor] = useState("rgba(0, 0, 0, 1)");
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => {
    console.log("Showing modal");
    setShowModal(true);
  };

  const handleClose = () => {
    console.log("Hiding modal");
    setShowModal(false);
  };

  const handleNavigation = (path) => {
    setTimeout(() => {
      window.location.href = path;
    }, 200); // 200 milliseconds delay
  };

  useEffect(() => {
    // Toggle collapse functionality
    const toggleNavbar = () => {
      setIsCollapsed((prevState) => !prevState);
    };

    // Scroll functionality to change background color
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setBgColor("rgba(0, 0, 0, 0.3)");
      } else {
        setBgColor("rgba(0, 0, 0, 1)");
      }
    };

    $(".navbar-toggler").on("click", toggleNavbar);
    window.addEventListener("scroll", handleScroll);

    // Clean up event listeners on unmount
    return () => {
      $(".navbar-toggler").off("click", toggleNavbar);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {/*----------Navbar-----*/}
      <FeedBackModal showModal={showModal} handleClose={handleClose} />
      <div className="topNavBar" style={{ backgroundColor: bgColor }}>
        <nav className="navbar navbar-expand-lg navbar-dark ">
          <div className="navbar-brand">
            <div
              className="logoHead d-flex align-items-center"
              style={{ cursor: "pointer" }}
            >
              <div onClick={() => handleNavigation("/")}>
                <img
                  src={Logo}
                  width="30"
                  height="40"
                  className="d-inline-block align-top"
                  alt="Logo"
                />
              </div>
              <div
                className="logotitle"
                onClick={() => handleNavigation("/")}
              >
                <h6>CHATGPT NOTEPAD</h6>
              </div>
            </div>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={!isCollapsed}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse justify-content-end ${
              !isCollapsed ? "show" : ""
            }`}
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <button
                  className="nav-link"
                  onClick={() => handleNavigation("/")}
                >
                  HOME
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link"
                  onClick={() => handleNavigation("/AboutUs")}
                >
                  ABOUT
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link"
                  onClick={() => handleNavigation("/TextEditorNew")}
                >
                  NOTEPAD
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link"
                  onClick={() => handleNavigation("/blog")}
                >
                  BLOG
                </button>
              </li>
              {/* <li className="nav-item">
                <button
                  className="nav-link"
                  onClick={() => handleNavigation("/privacy_policy")}
                >
                  PRIVACY & POLICY
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link"
                  onClick={() => handleNavigation("/terms_of_use")}
                >
                  TERMS & CONDITION
                </button>
              </li> */}
              <li className="nav-item">
                <button
                  className="nav-link"
                  onClick={() => handleNavigation("/ContactUs")}
                >
                  CONTACT US
                </button>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={handleShow}>
                  FEEDBACK
                </button>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default HeaderNew;
