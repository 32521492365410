import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  server_post_data,
  get_all_home_front,
} from "../ServiceConnection/serviceconnection.js";
import HeaderNew from "./HeaderNew.js";
import AdComponent from "./AdComponent.js";
import { ShoppingBag } from "lucide-react";
import { X } from "lucide-react";
function ViewBlogs() {
  const [addBlog, setaddBlog] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/blog/blog_detail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });
      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }

    return data_seo_link_final;
  };

  const handleLinkClick = (link, blank = "") => {
    // Reload the page when the link is clicked
    if (blank === "") {
      window.location.href = link;
    } else {
      window.open(link, blank);
    }
  };

  const DateormateBlogChange = (originalDateString) => {
    const dateObject = new Date(originalDateString);

    const formattedDateString = dateObject.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    return formattedDateString;
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    const fd = new FormData();
    fd.append("admin_id", "0");
    await server_post_data(get_all_home_front, fd)
      .then((Response) => {
        console.log(Response.data.message);
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          setSEOloop(Response.data.message.seo_loop);
          setaddBlog(Response.data.message.blog_list);
        }
      })
      .catch((error) => {});
  };

  // console.log(addBlog)
  // console.log(SEOloop)

  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "3";
    const call_id = "0";

    master_data_get(start_date, end_date, flag, call_id);
  }, []);
  const [isTextVisible, setIsTextVisible] = useState(true);

  const handleToggleVisibility = () => {
    setIsTextVisible(!isTextVisible);
  };
  return (
    <div>
      <div className="headerBttmMargin">
        {" "}
        <HeaderNew />
      </div>
      <div className="iconflotSubcont">
        <div
          className="icon-container"
          onClick={() => (window.location.href = "/SaleProduct")}
        >
          <ShoppingBag size={24} className="shopping-bag-icon" />
        </div>

        {isTextVisible && (
          <div className="hover-text-container position-relative">
            <div className="hover-text ">
              <p className="m-0" onClick={() => (window.location.href = "/SaleProduct")}>
                Own This Product Today!
              </p>
              <p className="m-0" onClick={() => (window.location.href = "/SaleProduct")}>
                {" "}
                Click to learn more!
              </p>

              <button onClick={handleToggleVisibility} className="close-button">
                <X className="close-icon" />
              </button>
            </div>
          </div>
        )}
      </div>
      <AdComponent slot="4982546863" format="auto" fullWidthResponsive="true" />
      <section className="blog">
        <div className="blogcontainer container-lg">
          <h2 className="cursiveHead">Blogs</h2>
          <h3 className="normalHead">Inspiring Your Next Journey</h3>
          <div className="blogsCardsContaier">
            <div className="row m-0">
              {addBlog.map((landmar, index) => (
                <div className="col-md-4 mb-3" key={index}>
                  <Link
                    onClick={() =>
                      handleLinkClick(
                        match_and_return_seo_link(landmar.primary_id)
                      )
                    }
                  >
                    <div className="bogscard">
                      <img
                        src={landmar.image_url}
                        alt="Chat GPT notepad"
                        title="Notepad GPT"
                      />
                      <div className="blogtext">
                        <h6>{landmar.title_name}</h6>
                        <p>
                          {DateormateBlogChange(landmar.entry_date)} |{" "}
                          {landmar.author}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <AdComponent
        slot="6948017615"
        format="autorelaxed"
        fullWidthResponsive="false"
      />
    </div>
  );
}

export default ViewBlogs;
