import React, { useState } from "react";
import HeaderNew from "./HeaderNew";
// import Header4 from "./Header4";

import Footer from "./Footer";
import "./css/AboutUs.css";
import AdComponent from "./AdComponent";
import {
  Mail,
  MessageSquare,
  TrendingUp,
  Users,
  Code,
  Package,
  DollarSign,
  ChevronRight,
  Globe,
  Zap,
  LineChart,
  ShoppingCart,
} from "lucide-react";
import {
  server_post_data,
  addContactData,
} from "../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
} from "../CommonJquery/CommonJquery.js";
const Salespage = () => {
  const [formSubmitted, setFormSubmitted] = useState(false); // New state to control form visibility
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    if (form_data) {
      let fd_from = combiled_form_data(form_data);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          if (Response.data.error) {
            // Handle error
          } else {
            empty_form(form_data);
            setFormSubmitted(true);
          }
        })
        .catch((error) => {
          // Handle network error
        });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <HeaderNew />
      <div className="bg-[#5a3ac6] text-white py-2 mt-16">
        <div className="container mx-auto px-4 text-center">
          <span className="text-sm font-medium">
            Limited Time Offer: Premium SaaS Business for Sale
          </span>
        </div>
      </div>

      {/* Previous header code remains the same */}

      <main>
        {/* Hero Section with Price */}
        <section className="container mx-auto px-4 py-16 md:py-24">
          <div className="max-w-4xl mx-auto text-center">
            <div className="inline-block bg-green-100 text-green-800 px-4 py-2 rounded-full text-sm font-semibold mb-6">
              Business Opportunity
            </div>
            <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6">
              Your Opportunity to Own a Thriving SaaS Business
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              A market-ready SaaS product designed to simplify text editing for
              users who rely on AI-generated content.
            </p>
            <div className="bg-white rounded-2xl shadow-xl p-8 mb-8 inline-block">
              <div className="text-[#5a3ac6] text-sm font-semibold mb-2">
                Acquisition Price
              </div>
              <div className="text-5xl font-bold text-gray-900 mb-2">
                $20,000
              </div>
              <div className="text-gray-500 text-sm">
                One-time payment, full ownership
              </div>
            </div>
            <div className="flex justify-center gap-4">
              <a
                href="#sales-portal"
                className="inline-flex items-center gap-2 bg-[#5a3ac6] text-white px-8 py-3 rounded-lg hover:bg-[#4c31a8] transition-colors"
              >
                View Sales Details <ChevronRight className="w-4 h-4" />
              </a>
            </div>
          </div>
        </section>

        {/* Previous sections remain the same until before Contact Form */}

        {/* Sales Portal Section */}
        <section
          id="sales-portal"
          className="bg-gradient-to-b from-[#5a3ac6] to-[#4c31a8] text-white py-16"
        >
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto">
              <h2 className="text-3xl font-bold text-center mb-8">
                Sales Portal
              </h2>
              <div className="bg-white/10 backdrop-blur-lg rounded-xl p-8">
                <div className="grid gap-6">
                  <div className="flex justify-between items-center pb-4 border-b border-white/20">
                    <div>
                      <h3 className="font-semibold">Full Business Package</h3>
                      <p className="text-white/80 text-sm">
                        Complete ownership transfer
                      </p>
                    </div>
                    <div className="text-2xl font-bold">$20,000</div>
                  </div>
                  <div className="space-y-3">
                    <div className="flex items-center gap-2">
                      <ShoppingCart className="w-5 h-5" />
                      <span>Includes all assets and rights</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Users className="w-5 h-5" />
                      <span>1,400+ active user base</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Globe className="w-5 h-5" />
                      <span>Global market presence</span>
                    </div>
                  </div>
                  <a
                    href="#contact"
                    className="block text-center bg-white text-[#5a3ac6] px-6 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors"
                  >
                    Contact to Purchase
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Contact Form */}
        <section id="contact" className="bg-white py-16">
          <div className="container mx-auto px-4">
            <div className="max-w-2xl mx-auto">
              <h2 className="text-3xl font-bold text-center mb-3">
                Ready to Take Over?
              </h2>
              <p className="text-center text-md mb-8">
                Fill out the form below, and our team will get back to you soon.
              </p>
              <div className="bg-gray-50 rounded-xl shadow-md p-8">
                {/* <div className="flex flex-col gap-6 mb-8">
                  <div className="flex items-center gap-2">
                    <Mail className="w-5 h-5 text-[#5a3ac6]" />
                    <a
                      href="mailto:gourav@triosoft.ai"
                      className="text-[#5a3ac6] hover:underline"
                    >
                      gourav@triosoft.ai
                    </a>
                  </div>
                  <div className="flex items-center gap-2">
                    <MessageSquare className="w-5 h-5 text-[#5a3ac6]" />
                    <span>Fill out the form below</span>
                  </div>
                </div> */}

                <form className="space-y-4" id="feedbackForm">
                  {formSubmitted && (
                    <div className="thank-you-message text-center">
                      <h4>Thank you for your Interest!</h4>
                    </div>
                  )}
                  <div className="grid md:grid-cols-2 gap-4">
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="user_name"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#5a3ac6] focus:border-[#5a3ac6] form-control trio_mandatory"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="user_email"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#5a3ac6] focus:border-[#5a3ac6] form-control trio_mandatory trio_email"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="message"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Message
                    </label>
                    <textarea
                      id="message"
                      rows={4}
                      name="user_msg"
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-[#5a3ac6] focus:border-[#5a3ac6] form-control"
                    ></textarea>
                  </div>
                  <button
                    type="button"
                    onClick={() =>
                      handleSaveChangesdynamic("feedbackForm", addContactData)
                    }
                    className="w-full bg-[#5a3ac6] text-white px-6 py-3 rounded-lg hover:bg-[#4c31a8] transition-colors"
                  >
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Salespage;
