import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./css/TextEditor.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button, Dropdown } from "react-bootstrap";
import deleteicn from "../assets/deleteicn.svg";
import deleteRed from "../assets/deleteRed.svg";
import Down from "../assets/down-arrow.png";
import rightMArk from "../assets/rightMArk.svg";
import HeaderTextEditor from "./headerTextEditor";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { formatDistanceToNow } from "date-fns";
import DOMPurify from "dompurify";
import $ from "jquery";
import { ShoppingBag } from "lucide-react";
import { X } from "lucide-react";
const TextEditor = () => {
  const [hovered, setHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [notes, setNotes] = useState([]);
  const [activeNote, setActiveNote] = useState(null);
  const [lineNumber, setLineNumber] = useState(0);
  const [columnNumber, setColumnNumber] = useState(0);
  const [wordCount, setWordCount] = useState(0);
  const [editorFocused, setEditorFocused] = useState(false);
  const [history, setHistory] = useState([]);

  const fonts_list = [
    { family: "Arial", value: "Arial" },
    { family: "Courier", value: "Courier-New" },
    { family: "Georgia", value: "Georgia" },

    { family: "Verdana", value: "Verdana" },
  ];
  // Map through the fonts_list to create truncated labels
  const truncatedFontsList = fonts_list.map((font) => {
    const truncatedLabel =
      font.family.split(" ").slice(0, 1).join(" ") +
      (font.family.split(" ").length > 1 ? "..." : "");
    return { ...font, label: truncatedLabel };
  });

  const [showFindReplaceModal, setShowFindReplaceModal] = useState(false);
  const [findText, setFindText] = useState("");
  const [replaceText, setReplaceText] = useState("");
  const [matchCase, setMatchCase] = useState(false);
  const [wholeWord, setWholeWord] = useState(false);
  const [findCount, setFindCount] = useState(0);
  const [replaceCount, setReplaceCount] = useState(0);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showSpecialCharacterModal, setShowSpecialCharacterModal] =
    useState(false);

  const [showEmojiModal, setShowEmojiModal] = useState(false);
  const [selectedSpecialCharacter, setSelectedSpecialCharacter] = useState("");
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [isStatusBarVisible, setIsStatusBarVisible] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isSelected, setIsSelected] = useState(false);

  const [editorContent, setEditorContent] = useState(
    activeNote ? activeNote.body : ""
  );

  console.log(editorContent);

  const [fonts, setFonts] = useState([]);
  const [spellCheckEnabled, setSpellCheckEnabled] = useState(true);

  const textareaRef = useRef();

  const fileInputRef = useRef(null);
  const titleRef = useRef(null);
  const searchRef = useRef(null);
  // Retrieve and sort notes from local storage
  const getAllNotes = () => {
    const notes = JSON.parse(localStorage.getItem("notesapp-notes") || "[]");
    return notes.sort((a, b) => new Date(b.updated) - new Date(a.updated));
  };
  useEffect(() => {
    if (activeNote) {
      setHistory([...history, activeNote.body]);
      setEditorContent(activeNote.body);
    }
    const Fontcustom = ReactQuill.Quill.import("formats/font");
    Fontcustom.whitelist = fonts_list.map((font) => font.value);
    ReactQuill.Quill.register(Fontcustom, true);
  }, [activeNote]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const handlePaste = (content) => {
    console.log("handlePaste called");

    // Ensure content is a string
    if (typeof content !== "string") {
      console.error("Content is not a string:", content);
      return;
    }

    if (textareaRef.current) {
      const editor = textareaRef.current.getEditor();
      if (editor) {
        const range = editor.getSelection();
        if (range) {
          // Remove asterisks (*) and hash symbols (#)
          const cleanedContent = content.replace(/[\*#]/g, "");
          console.log("Cleaned content:", cleanedContent);

          if (range.length > 0) {
            // Replace selected text
            editor.deleteText(range.index, range.length);
            editor.insertText(range.index, cleanedContent);
            editor.setSelection(range.index + cleanedContent.length, 0);
          } else {
            // Insert content at cursor position
            editor.insertText(range.index, cleanedContent);
            editor.setSelection(range.index + cleanedContent.length, 0);
          }
        } else {
          console.warn("No selection range found");
        }
      } else {
        console.error("Editor is undefined");
      }
    } else {
      console.error("textareaRef.current is undefined");
    }
  };

  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.key === "v") {
      event.preventDefault();
      navigator.clipboard
        .readText()
        .then((pastedContent) => {
          handlePaste(pastedContent);
        })
        .catch((err) => {
          console.error("Failed to read clipboard contents: ", err);
        });
    }
  };

  useEffect(() => {
    console.log("useEffect called");
    // Add the keydown event listener when the component mounts
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      // Cleanup the event listener when the component unmounts
      document.removeEventListener("keydown", handleKeyDown);
      console.log("Keydown event listener removed");
    };
  }, []);
  const selectAllText = (element) => {
    if (element) {
      element.select();
    }
  };
  const handleKeyboardShortcuts = (event) => {
    const focusedElement = document.activeElement;

    if (event.ctrlKey) {
      // Check if the focused element is one of the input fields
      if (
        focusedElement === titleRef.current ||
        focusedElement === searchRef.current
      ) {
        if (event.key === "d") {
          event.preventDefault();
          handleFileSave();
        } else if (event.key === "p") {
          // event.preventDefault();
          handlePrint();
        } else if (event.key === "z") {
          event.preventDefault();
          // editor.history.undo();
        } else if (event.key === "y") {
          event.preventDefault();
          // editor.history.redo();
        } else if (event.key === "x") {
          event.preventDefault();
          handleCut();
        } else if (event.key === "c") {
          // Allow default copy behavior
          handleCopy();
        } else if (event.key === "r") {
          handleFindAndReplace();
        } else if (event.key === "f") {
          event.preventDefault();
          toggleFullScreen();
        } else if (event.key === "ArrowRight") {
          event.preventDefault();
          handleIncreaseFontSize();
        } else if (event.key === "ArrowLeft") {
          event.preventDefault();
          handleDecreaseFontSize();
        } else if (event.key === "a") {
          event.preventDefault();
          selectAllText(focusedElement);
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyboardShortcuts);

    return () => {
      document.removeEventListener("keydown", handleKeyboardShortcuts);
    };
  }, [isFullScreen]);

  // Save or update a note in local storage
  // const saveNote = (noteToSave) => {
  //   // Retrieve all notes from local storage
  //   const notes = getAllNotes();

  //   // Find an existing note with the same ID
  //   const existing = notes.find((note) => note.id === noteToSave.id);

  //   // Log the note to save
  //   console.log("Note to save:", noteToSave);

  //   if (existing) {
  //     // Update existing note's title and body
  //     existing.title = noteToSave.title || "";
  //     existing.body = noteToSave.body || "";
  //     console.log("Existing note title:", existing.title);
  //     console.log("Existing note body:", existing.body);
  //     existing.updated = new Date().toISOString();
  //   } else {
  //     // Assign a new ID and timestamp to the new note
  //     noteToSave.id = Math.floor(Math.random() * 1000000);
  //     noteToSave.updated = new Date().toISOString();
  //     notes.push(noteToSave);
  //     console.log("New note title:", noteToSave.title);
  //     console.log("New note body:", noteToSave.body);
  //   }

  //   // Save the updated notes to local storage
  //   localStorage.setItem("notesapp-notes", JSON.stringify(notes));

  //   // Log the final notes array
  //   console.log("Updated notes:", notes);
  // };

  const saveNote = (noteToSave) => {
    const notes = getAllNotes();
    const existing = notes.find((note) => note.id === noteToSave.id);

    if (existing) {
      existing.title = noteToSave.title || "";
      existing.body = noteToSave.body || "";
      existing.updated = new Date().toISOString();
    } else {
      noteToSave.updated = new Date().toISOString();
      notes.push(noteToSave);
    }

    localStorage.setItem("notesapp-notes", JSON.stringify(notes));
    console.log("Notes saved to local storage:", notes); // Debugging line
  };

  // Delete a note from local storage
  const deleteNote = (id) => {
    const notes = getAllNotes();
    const newNotes = notes.filter((note) => note.id !== id);
    localStorage.setItem("notesapp-notes", JSON.stringify(newNotes));
  };

  // Refresh notes and set active note
  useEffect(() => {
    refreshNotes();
  }, []);

  const refreshNotes = () => {
    let notes = getAllNotes();

    // Add a default note if none exist
    if (notes.length === 0) {
      const defaultNote = {
        title: "",
        body: "",
      };
      saveNote(defaultNote);
      notes = getAllNotes();
    }

    setNotes(notes);

    if (notes.length > 0) {
      setActiveNote(notes[0]);
    }
  };

  const handleIncreaseFontSize = () => {
    const editor = textareaRef.current?.getEditor();
    if (editor) {
      const currentSize = parseInt(
        window.getComputedStyle(editor.root).fontSize
      );
      editor.format("size", `${currentSize + 1}px`);
    }
  };

  const handleDecreaseFontSize = () => {
    const editor = textareaRef.current?.getEditor();
    if (editor) {
      const currentSize = parseInt(
        window.getComputedStyle(editor.root).fontSize
      );
      editor.format("size", `${currentSize - 1}px`);
    }
  };

  // Select a note from the list
  const handleNoteSelect = (noteId) => {
    // Find the selected note based on the noteId
    const selectedNote = filteredNotes.find((note) => note.id === noteId);

    if (selectedNote) {
      // Update the active note state
      setActiveNote(selectedNote);

      // Set the editor content to the selected note's body
      setEditorContent(selectedNote.body);

      // Reset history and historyIndex
      setHistory([]);
      setHistoryIndex(-1);
    }
  };

  // const handleNoteAdd = () => {
  //   const newNote = { title: "", body: "" };
  //   saveNote(newNote);

  //   refreshNotes();
  //   updateEditorInfo(newNote.body);
  //   setEditorContent(newNote.body);
  // };

  const handleNoteAdd = () => {
    const newNote = {
      id: Math.floor(Math.random() * 1000000),
      title: "",
      body: "<p><br></p>", // Ensure body is initialized
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
    };

    saveNote(newNote);
    setActiveNote(newNote);
    refreshNotes();
    setEditorContent(""); // Clear editor for new note
  };

  // const handleNoteEdit = (title, body) => {
  //   const sanitizedBody = DOMPurify.sanitize(body);
  //   saveNote({ id: activeNote.id, title: title || "", body: sanitizedBody });
  //   console.log(sanitizedBody);
  //   refreshNotes();
  //   updateEditorInfo(sanitizedBody);

  //   setEditorContent(sanitizedBody);
  // };

  const handleNoteEdit = (title, body) => {
    const sanitizedBody = DOMPurify.sanitize(body);

    console.log("Sanitized Body:", sanitizedBody); // Debugging line

    // Save the note with the sanitized content
    saveNote({
      id: activeNote.id,
      title: title || "",
      body: sanitizedBody,
    });

    // Refresh the notes list and update the editor with the new content
    refreshNotes();
    updateEditorInfo(sanitizedBody);
    setEditorContent(sanitizedBody);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const editor = textareaRef.current.getEditor();
        const range = editor.getSelection();
        if (range) {
          const imageUrl = reader.result;
          editor.insertEmbed(range.index, "image", imageUrl);

          // Apply styling and make images resizable and draggable
          setTimeout(() => ImageResizeAndDrag(), 0); // Delay to ensure images are inserted
        } else {
          console.error("No selection found");
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const ImageResizeAndDrag = () => {
    const images = document.querySelectorAll(".resizable-draggable");
    images.forEach((img) => {
      img.addEventListener("mousedown", onMouseDown);

      function onMouseDown(e) {
        const startX = e.clientX;
        const startY = e.clientY;
        const startWidth = parseInt(
          document.defaultView.getComputedStyle(img).width,
          10
        );
        const startHeight = parseInt(
          document.defaultView.getComputedStyle(img).height,
          10
        );

        function onMouseMove(e) {
          img.style.width = startWidth + (e.clientX - startX) + "px";
          img.style.height = startHeight + (e.clientY - startY) + "px";
        }

        function onMouseUp() {
          document.removeEventListener("mousemove", onMouseMove);
          document.removeEventListener("mouseup", onMouseUp);
        }

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
      }
    });
  };
  // Handle note deletion
  const handleNoteDelete = (noteId) => {
    setNoteToDelete(noteId);
    setShowModal(true);
  };

  // Confirm delete action
  const confirmDelete = () => {
    if (noteToDelete !== null) {
      deleteNote(noteToDelete);
      refreshNotes();
      setShowModal(false);
      setNoteToDelete(null);
    }
  };

  // Cancel delete action
  const cancelDelete = () => {
    setShowModal(false);
    setNoteToDelete(null);
  };

  // Update editor information (line count, column count, word count)
  const updateEditorInfo = (body) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = body;
    const text = tempDiv.innerText || tempDiv.textContent;

    // Calculate words
    const words = text.split(/\s+/).filter(Boolean).length;
    setWordCount(words);
  };
  const handleCursorPosition = () => {
    const editor = textareaRef.current?.getEditor();
    if (editor) {
      const selection = editor.getSelection();
      if (selection) {
        const { index } = selection; // Get cursor index
        const text = editor.getText(); // Get entire text from editor
        const lines = text.substring(0, index).split("\n");
        const lineNumber = lines.length;
        const columnNumber = lines[lines.length - 1].length;

        setLineNumber(lineNumber);
        setColumnNumber(columnNumber);
      }
    }
  };

  // Update cursor position when content changes
  useEffect(() => {
    handleCursorPosition();
  }, [editorContent]);

  // Handle editor focus
  const handleEditorFocus = () => {
    setEditorFocused(true);
  };

  // Handle editor blur
  const handleEditorBlur = () => {
    setEditorFocused(false);
  };

  // Filter notes based on search term
  const filteredNotes = notes.filter((note) =>
    note.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const stripHtmlTags = (html) => {
    // Create a temporary div element
    const tempDiv = document.createElement("div");
    // Set the HTML content to the div
    tempDiv.innerHTML = html;
    // Return the text content of the div
    return tempDiv.textContent || tempDiv.innerText || "";
  };
  // Render note list with search filter
  const truncateText = (text, maxLength = 30) => {
    if (!text) return "";
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };
  // Function to render note list with title, content preview, and date
  const renderNoteList = () =>
    filteredNotes.map((note) => {
      const displayTitle = note.title.trim() === "" ? "Untitled" : note.title;
      const plainBody = stripHtmlTags(note.body);
      const displayBody =
        plainBody.trim() === ""
          ? "Write Something"
          : truncateText(plainBody, 10);

      return (
        <div
          key={note.id}
          className={`notes__list-item ${
            activeNote && activeNote.id === note.id
              ? "notes__list-item--selected"
              : ""
          }`}
          onClick={() => handleNoteSelect(note.id)}
          onDoubleClick={() => handleNoteDelete(note.id)}
        >
          <div className="notes__small-title">{displayTitle}</div>
          <div className="notes__small-preview">{displayBody}</div>
          <div className="notes__small-updated">
            {formatDistanceToNow(new Date(note.updated), { addSuffix: true })}
          </div>
        </div>
      );
    });

  const triggerFileInputClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileOpen = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const lines = content.split("\n");
        const title = lines[0] || "Untitled Note";
        const body = lines.slice(1).join("\n");
        handleNoteEdit(title, body);
      };
      reader.readAsText(file);
    }
  };
  // Render note preview
  const renderNotePreview = () => {
    if (!activeNote) {
      return (
        <div className="notes__preview" style={{ visibility: "hidden" }} />
      );
    }

    // Function to handle paste event

    return (
      <>
        <div className="notes__preview">
          <div className="titleText">
            <div className="inputHeadingleft">
              <input
                ref={titleRef}
                className="notes__title"
                type="text"
                placeholder="Untitled Note"
                value={activeNote.title}
                onChange={(e) =>
                  handleNoteEdit(e.target.value, activeNote.body)
                }
              />
            </div>
            <div className="deleteBtn">
              <button onClick={() => handleNoteDelete(activeNote.id)}>
                <img
                  src={hovered ? deleteRed : deleteicn}
                  alt="Delete Icon"
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                />
              </button>
            </div>
          </div>

          <div className="textAreraSectiom">
            <ReactQuill
              ref={textareaRef}
              value={activeNote ? activeNote.body : ""}
              // onChange={(content) => {
              //   setEditorContent(content);
              //   updateEditorInfo(content);
              //   handleNoteEdit(activeNote.title, content);
              // }}
              onChange={(content) => {
                setEditorContent(content);
                handleNoteEdit(activeNote ? activeNote.title : "", content);
              }}
              onFocus={handleEditorFocus}
              onBlur={handleEditorBlur}
              onPaste={handlePaste}
              placeholder="Write something..."
              theme="snow"
              modules={{
                toolbar: [
                  [{ font: truncatedFontsList.map((font) => font.value) }],
                  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  ["blockquote"],
                  [{ align: [] }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                  [{ lineHeight: ["1", "1.15", "1.5", "2", "2.5", "3"] }], // Added line height
                  [{ direction: "rtl" }], // text direction

                  ["clean"], // remove formatting button
                ],
                clipboard: {
                  matchVisual: false,
                },
              }}
              formats={[
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "script",
                "header",
                "list",
                "bullet",
                "indent",

                "align",
                "direction",
                "lineHeight",
              ]}
              id="editor"
            />
          </div>

          {editorFocused && (
            <div className="editor-info statusbar">
              <div className="LineCounter">
                <label>Line: {lineNumber}</label>
                <label>Column: {columnNumber}</label>
              </div>
              <div className="columnCounter">
                <label>Words: {wordCount}</label>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const handleFileSave = () => {
    if (activeNote) {
      const blob = new Blob([`${activeNote.title}\n${activeNote.body}`], {
        type: "text/plain;charset=utf-8",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${activeNote.title || "Untitled Note"}.txt`;
      a.click();
      URL.revokeObjectURL(url);
    }
  };
  //file print
  const handlePrint = () => {
    if (activeNote) {
      const printWindow = window.open("", "", "width=800,height=600");
      printWindow.document.open();
      printWindow.document.write(`
      <html>
        <head>
          <title>Print Note</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 20px; }
          </style>
        </head>
        <body>
          <h1>${activeNote.title || "Untitled Note"}</h1>
          <pre>${activeNote.body}</pre>
        </body>
      </html>
    `);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
    }
  };

  //Handle undo
  const handleUndo = () => {
    if (historyIndex > 0) {
      const prevState = history[historyIndex - 1];
      setActiveNote({
        ...activeNote,
        title: prevState.title,
        body: prevState.body,
      });
      setHistoryIndex(historyIndex - 1);
    }
  };

  //Handle redo
  const handleRedo = () => {
    if (historyIndex < history.length - 1) {
      const nextState = history[historyIndex + 1];
      setActiveNote({
        ...activeNote,
        title: nextState.title,
        body: nextState.body,
      });
      setHistoryIndex(historyIndex + 1);
    }
  };

  //Handle cut
  const handleCut = () => {
    const focusedElement = document.activeElement;

    if (
      focusedElement instanceof HTMLInputElement ||
      focusedElement instanceof HTMLTextAreaElement
    ) {
      focusedElement.focus(); // Ensure the element is focused

      try {
        // Execute the cut command
        document.execCommand("cut");
      } catch (err) {
        console.error("Failed to cut text: ", err);
      }
    } else {
      console.error("The focused element is not a valid input or textarea.");
    }
  };

  // Handle copy
  const handleCopy = () => {
    const focusedElement = document.activeElement;

    if (
      focusedElement instanceof HTMLInputElement ||
      focusedElement instanceof HTMLTextAreaElement
    ) {
      const { selectionStart, selectionEnd, value } = focusedElement;
      const selectedText = value.substring(selectionStart, selectionEnd);

      // Create a temporary element to hold the selected text
      const tempElement = document.createElement("div");
      tempElement.style.position = "absolute";
      tempElement.style.left = "-9999px";
      tempElement.style.top = "0";
      tempElement.innerHTML = selectedText;

      // Append the element to the body
      document.body.appendChild(tempElement);

      // Select and copy the content
      const range = document.createRange();
      range.selectNode(tempElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);

      try {
        // Copy the selected content
        document.execCommand("copy");
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }

      // Remove the temporary element
      document.body.removeChild(tempElement);
    }
  };

  // Handle delete
  const handleDelete = () => {
    if (activeNote) {
      deleteNote(activeNote.id);
      refreshNotes();
    }
  };

  // Handle select all
  const handleSelectAll = () => {
    if (textareaRef.current) {
      const quill = textareaRef.current.getEditor(); // Get the Quill editor instance
      const length = quill.getLength(); // Get the length of the document
      quill.setSelection(0, length); // Select all text
      setIsSelected(!isSelected); // Toggle the selected state
    }
  };
  const handleFindAndReplace = () => {
    let bodyText = activeNote.body;
    let searchPattern = findText;

    if (!matchCase) {
      searchPattern = new RegExp(searchPattern, "gi");
    } else {
      searchPattern = new RegExp(searchPattern, "g");
    }

    if (wholeWord) {
      searchPattern = new RegExp(
        `\\b${searchPattern.source}\\b`,
        searchPattern.flags
      );
    }

    const matches = [...bodyText.matchAll(searchPattern)];
    setFindCount(matches.length);

    const replacedText = bodyText.replace(searchPattern, replaceText);
    setReplaceCount(matches.length);
    handleNoteEdit(activeNote.title, replacedText);
    setShowFindReplaceModal(false);
  };
  const insertDateTime = () => {
    if (textareaRef.current) {
      const quill = textareaRef.current.getEditor(); // Get the Quill editor instance
      const range = quill.getSelection(); // Get the current selection
      const date = new Date();
      const dateString = date.toLocaleString();

      if (range) {
        // Check if there is a valid selection
        const startPos = range.index;
        const endPos = range.index; // The end position is the same as the start in this case

        // Insert date and time at the current cursor position
        quill.insertText(startPos, dateString);

        // Move the cursor to the end of the inserted text
        quill.setSelection(startPos + dateString.length, 0);

        // Ensure the editor stays focused
        quill.focus();
      }
    }
  };
  const handleSpecialCharacterClick = () => {
    setShowSpecialCharacterModal(true);
  };

  const handleEmojiClick = () => {
    setShowEmojiModal(true);
  };

  const specialCharacters = [
    "©",
    "®",
    "™",
    "§",
    "¶",
    "•",
    "∞",
    "€",
    "£",
    "¥",
    "¢",
    "₹",
    "₣",
    "₩",
    "₽",
    "₪",
    "฿",
    "₲",
    "₼",
    "₿",
    "♠",
    "♣",
    "♥",
    "♦",
    "♂",
    "♀",
    "♪",
    "♫",
    "✈",
    "☀",
    "☁",
    "☂",
    "☃",
    "★",
    "☆",
    "✿",
    "☘",
    "☑",
    "✔",
    "✘",
    "✽",
    "✾",
    "✿",
    "✸",
    "✹",
    "✺",
    "✻",
    "✼",
    "✽",
    "✾",
    "✿",
    "✸",
    "✹",
    "✺",
    "✻",
    "✼",
    "✽",
    "✾",
    "✿",
    "✸",
  ];

  const insertSpecialCharacter = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;
      const currentText = textarea.value;

      const newText =
        currentText.substring(0, startPos) +
        selectedSpecialCharacter +
        currentText.substring(endPos, currentText.length);

      handleNoteEdit(activeNote.title, newText);
      setShowSpecialCharacterModal(false);
      setSelectedSpecialCharacter("");
    }
  };
  const emojis = [
    "😊",
    "😂",
    "😍",
    "🤣",
    "😃",
    "😄",
    "😅",
    "😆",
    "😉",
    "😊",
    "😋",
    "😎",
    "😍",
    "😘",
    "😗",
    "😙",
    "😚",
    "☺️",
    "😇",
    "🤗",
    "🤔",
    "🤨",
    "🤯",
    "😐",
    "😑",
    "😶",
    "🙄",
    "😏",
    "😣",
    "😥",
    "😮",
    "🤐",
    "😯",
    "😪",
    "😫",
    "😴",
    "🤤",
    "😵",
    "😲",
    "😳",
    "🥺",
    "😦",
    "😧",
    "😨",
    "😰",
    "😥",
    "😓",
    "🤗",
    "🤩",
    "🤪",
    "🤫",
    "🤭",
    "🧐",
    "🤓",
    "😈",
    "👿",
    "👹",
    "👺",
    "🤡",
    "👻",
  ];

  const insertEmoji = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;
      const currentText = textarea.value;

      const newText =
        currentText.substring(0, startPos) +
        selectedEmoji +
        currentText.substring(endPos, currentText.length);

      handleNoteEdit(activeNote.title, newText);
      setShowEmojiModal(false);
      setSelectedEmoji("");
    }
  };
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((err) => {
        console.log(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().catch((err) => {
          console.log(
            `Error attempting to exit full-screen mode: ${err.message} (${err.name})`
          );
        });
        setIsFullScreen(false);
      }
    }
  };
  const toggleStatusBar = () => {
    setIsStatusBarVisible(!isStatusBarVisible);
  };
  const toggleSpellCheck = () => {
    setSpellCheckEnabled(!spellCheckEnabled);
  };

  useEffect(() => {
    const editorElement = $(textareaRef.current?.getEditor().root);

    if (spellCheckEnabled) {
      // Enable spell check
      editorElement.attr("spellcheck", "true");
      editorElement.css("text-decoration", "none");
    } else {
      // Disable spell check
      editorElement.attr("spellcheck", "false");
      editorElement.css("text-decoration", "none");
    }
  }, [spellCheckEnabled]);

  useEffect(() => {
    // Ensure spell check state is applied on component mount
    const editorElement = $(textareaRef.current?.getEditor().root);
    editorElement.attr("spellcheck", spellCheckEnabled ? "true" : "false");
  }, []);

  const [isTextVisible, setIsTextVisible] = useState(true);

  const handleToggleVisibility = () => {
    setIsTextVisible(!isTextVisible);
  };
  return (
    <>
      {!isFullScreen && <HeaderTextEditor toggleSidebar={toggleSidebar} />}
      <div className="iconflotSubcont textNotpadconfloting">
        <div
          className="icon-container"
          onClick={() => (window.location.href = "/SaleProduct")}
        >
          <ShoppingBag size={24} className="shopping-bag-icon" />
        </div>

        {isTextVisible && (
          <div className="hover-text-container position-relative">
            <div className="hover-text ">
              <p className="m-0" onClick={() => (window.location.href = "/SaleProduct")}>
                Own This Product Today!
              </p>
              <p className="m-0" onClick={() => (window.location.href = "/SaleProduct")}>
                {" "}
                Click to learn more!
              </p>

              <button onClick={handleToggleVisibility} className="close-button">
                <X className="close-icon" />
              </button>
            </div>
          </div>
        )}
      </div>
      <div
        className={`text-editor-container ${isFullScreen ? "fullscreen" : ""}`}
      >
        <div className="notes" id="app">
          {/* Sidebar */}

          <div className="displayFlex">
            <div
              className={`notes__sidebar ${
                isSidebarOpen ? "sidebar-open" : ""
              }`}
            >
              <div className="CreateNOteBTn">
                <button type="button" onClick={handleNoteAdd}>
                  + Create New
                </button>
              </div>

              <div className="notes__search mt-2">
                <input
                  ref={searchRef}
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="notes__list mt-2">{renderNoteList()}</div>
            </div>

            {/* Editor Place */}
            <div className="EditorContainer">
              <div className="ButtnCont">
                {" "}
                <div className="TexteditBtns">
                  <div className="btn-group" role="group" aria-label="Toolbar">
                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        File
                        <img src={Down} alt="Down Arrow" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#/new" onClick={handleNoteAdd}>
                          New
                        </Dropdown.Item>
                        <Dropdown.Item onClick={triggerFileInputClick}>
                          Open
                        </Dropdown.Item>

                        <Dropdown.Item
                          href="#/save-as"
                          className="SavUSSectio"
                          onClick={handleFileSave}
                        >
                          Save As
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/print"
                          className="pntt"
                          onClick={handlePrint}
                        >
                          Print
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        Edit
                        <img src={Down} alt="Down Arrow" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {/* <Dropdown.Item onClick={handleUndo}>Undo</Dropdown.Item>
                        <Dropdown.Item onClick={handleRedo}>Redo</Dropdown.Item>
                        <Dropdown.Item onClick={handleCut}>Cut</Dropdown.Item>
                        <Dropdown.Item onClick={handleCopy}>Copy</Dropdown.Item>
                        <Dropdown.Item onClick={handlePaste}>
                          Paste
                        </Dropdown.Item> */}
                        <Dropdown.Item onClick={handleDelete}>
                          Delete
                        </Dropdown.Item>
                        {/* <Dropdown.Item onClick={handleSelectAll}>
                          {isSelected && (
                            <img
                              className="rightMarkImg"
                              src={rightMArk}
                              alt="Checkmark"
                            />
                          )}
                          Select All
                        </Dropdown.Item> */}
                        <Dropdown.Item
                          onClick={() => setShowFindReplaceModal(true)}
                        >
                          Find & Replace
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        Insert
                        <img src={Down} alt="Down Arrow" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={insertDateTime}>
                          Date & Time
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleSpecialCharacterClick}>
                          Characters
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleEmojiClick}>
                          Emoji
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    {/* <Dropdown>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        Format
                        <img src={Down} alt="Down Arrow" />
                      </Dropdown.Toggle>
                     <Dropdown.Menu>
                        <Dropdown.Item onClick={handleFontClick}>
                          Font
                        </Dropdown.Item>
                      </Dropdown.Menu> 
                    </Dropdown> */}

                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        Tools
                        <img src={Down} alt="Down Arrow" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={toggleSpellCheck}>
                          {spellCheckEnabled && (
                            <img
                              className="rightMarkImg"
                              src={rightMArk}
                              alt="Checkmark"
                            />
                          )}
                          Spell Check
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        View
                        <img src={Down} alt="Down Arrow" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={toggleStatusBar}
                          className="dropdown-item"
                        >
                          {isStatusBarVisible && (
                            <img
                              className="rightMarkImg"
                              src={rightMArk}
                              alt="Checkmark"
                            />
                          )}
                          Status Bar
                        </Dropdown.Item>

                        <Dropdown.Item onClick={toggleFullScreen}>
                          {" "}
                          {isFullScreen && (
                            <img
                              className="rightMarkImg"
                              src={rightMArk}
                              alt="Checkmark"
                            />
                          )}
                          Full Screen
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              {renderNotePreview()}
            </div>
          </div>
        </div>

        {/* Modal */}
        <Modal show={showModal} onHide={() => setShowModal(false)} centred>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this note?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancelDelete}>
              Cancel
            </Button>
            <Button variant="danger" onClick={confirmDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Modal-2 */}
        <Modal
          className="FindReplace"
          show={showFindReplaceModal}
          onHide={() => setShowFindReplaceModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Find and Replace</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-lg">
              <div className="row mb-3">
                <div className="col-3 d-flex align-items-center">
                  <label>Find</label>
                </div>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    value={findText}
                    onChange={(e) => setFindText(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-3 d-flex align-items-center">
                  <label>Replace</label>
                </div>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    value={replaceText}
                    onChange={(e) => setReplaceText(e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="row">
                <div className="col-3 d-flex align-items-center">
                  <label></label>
                </div>
                <div className="col-9 mt-3">
                  <div className="CHeckMatchs">
                    <div className="d-flex my-2">
                      <input
                        type="checkbox"
                        checked={matchCase}
                        onChange={() => setMatchCase(!matchCase)}
                      />
                      <label> Match Case</label>
                    </div>
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        checked={wholeWord}
                        onChange={() => setWholeWord(!wholeWord)}
                      />
                      <label> Whole Word</label>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleFindAndReplace}>
              Replace
            </Button>
          </Modal.Footer>
        </Modal>
        {/*modal-3*/}
        <Modal
          className="sybolchooseModal"
          show={showSpecialCharacterModal}
          onHide={() => setShowSpecialCharacterModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Special Character</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="emojiCOnt">
              {specialCharacters.map((character, index) => (
                <button
                  key={index}
                  className={
                    selectedSpecialCharacter === character ? "active" : ""
                  }
                  onClick={() => setSelectedSpecialCharacter(character)}
                >
                  {character}
                </button>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="InsertEmogyBtn"
              variant="primary"
              onClick={insertSpecialCharacter}
            >
              Insert
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showEmojiModal}
          onHide={() => setShowEmojiModal(false)}
          className="sybolchooseModal2"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Emojis</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="emojiCOnt">
              {emojis.map((emoji, index) => (
                <button
                  key={index}
                  className={selectedEmoji === emoji ? "active" : ""}
                  onClick={() => setSelectedEmoji(emoji)}
                >
                  {emoji}
                </button>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="InsertEmogyBtn"
              variant="primary"
              onClick={insertEmoji}
            >
              Insert
            </Button>
          </Modal.Footer>
        </Modal>

        {/*modal-4-----fontModal*/}

        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          accept=".txt"
          onChange={handleFileOpen}
        />
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
      </div>
    </>
  );
};

export default TextEditor;
